.pages{
  &.layout_page_thin{
    > .container{
      max-width: 1000px!important;
    }
  }

  .page-content{
    font-family: $_font_first;
    line-height: 1.7;
    font-size: 1.15rem;

    @include md{
      font-size: 1.25rem;
    }

    p{
      margin: 40px 0;
      a{
        color: #000;
        text-decoration: underline;

        &:hover{
          color: getColor( 'primary' );
        }
      }
    }
  }
}