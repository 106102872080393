$colors: (
  'primary': #ff3399,
  'text-color': #323232,
  'bg-base' : #e9e9eb,
  'border-color': #ddd,
);

@function getColor( $names... ){
  $color_list : $colors;
  
  @each $color in $names{
      $color_list: map-get( $color_list, $color );
  }
  @return $color_list;
}