/*
WP default aligns
*/

/* =WordPress Core
-------------------------------------------------------------- */
.align{
	&none {
	    margin: 5px 20px 20px 0;
	}
	&left {
	    float: left;
	    margin: 5px 20px 20px 0;
	}
	&center{
	    display: block;
	    margin: 5px auto 5px auto;
	}
	&right {
	    float:right;
	    margin: 5px 0 20px 20px;
	}
	&wide{
		margin: 32px calc(25% - 25vw);
		max-width: 100vw;
		width: 100vw;
	}
	&full{
		margin: 32px calc(50% - 50vw);
		max-width: 100vw;
		width: 100vw;
	}
}


a img.align{
	&none {
	    margin: 5px 20px 20px 0;
	}
	&left {
	    float: left;
	    margin: 5px 20px 20px 0;
	}
	&right {
	    float: right;
	    margin: 5px 0 20px 20px;
	}
	&center {
	    display: block;
	    margin-left: auto;
	    margin-right: auto;
	}
}

.wp-caption {
    //background: #fff;
    //border: 1px solid #f0f0f0;
    //max-width: 96%; /* Image does not overflow the content area */
    //padding: 5px 3px 10px;
    text-align: center;

    &.align{
    	&none {
		    margin: 5px 20px 20px 0;
		}
		&left {
		    margin: 5px 20px 20px 0;
		}
		&right {
		    margin: 5px 0 20px 20px;
		}
    }

    img {
	    border: 0 none;
	    height: auto;
	    margin: 0;
	    max-width: 98.5%;
	    padding: 0;
	    width: auto;
	}

	p.wp-caption-text {
	    font-size: 11px;
	    line-height: 17px;
	    margin: 0;
	    padding: 0 4px 5px;
	}

}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
	&:focus {
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
		/* Above WP toolbar. */
	}
}

hr.wp-block-separator{
	border-top: 1px solid #e1e1e1;
	border-bottom: none;
}





@media only screen and (max-width: 767px) {
	figure.wp-block-embed-youtube{
		margin-left: 0;
		margin-right: 0;
	}
}