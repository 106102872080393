.noticia{
  .container-single-noticia{
    max-width: 1000px!important;
  }

  mark{
    background: rgba(darken(#ff3399, 20), .15)!important;
    color: #000!important;
  }
  h1{
    font-size: 2.3rem;
    line-height: 1.1;
    margin: 0;
    padding: 30px 0;
    text-align: center;
    font-weight: bold;
    letter-spacing: -1;

    @include md{
      font-size: 3.2rem;
      letter-spacing: -2;
    }
  }
  .time{
    text-align: center;
    opacity: .7;
    font-size: 1rem;
    padding-bottom: 15px;;
    i{
      margin-right: 5px;
    }
  }
  .excerpt{
    font-family: $_font_first;
    //font-style: italic;
    opacity: .6;
    padding: 20px 0;
    text-align: center;
    font-size: 1.25rem;
  }
  .content{
    font-family: $_font_first;
    line-height: 1.7;
    font-size: 1.15rem;

    @include md{
      font-size: 1.25rem;
    }


    p{
      margin: 40px 0;
      a{
        color: #000;
        text-decoration: underline;

        &:hover{
          color: getColor( 'primary' );
        }
      }
    }

    .wp-block-quote{
      p{
        font-size: 2rem;
        letter-spacing: -1px;
        margin: 20px 0;
      }
    }

    //** blogger normalize images
    a{
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  
  }
}