
footer{
  color: getColor( 'text-color' );
  //background: getColor( 'bg-base' );
  background:lighten(#2b2d42, 5% );
  padding: 50px 0 0;
  position: relative;

  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    @include gradient( getColor( 'primary' ), #ff6a4e, left );
    top: 0;
    position: absolute;
  }
  // border-top: getColor( 'primary' ) 5px solid;

  .logo-footer{
    display: block;
    width: 110px;
    opacity: .5;
    margin: auto;
    @include md{
      margin: inherit;
      width: 150px;
    }

    svg{
      width: 100%;
      height: auto;
    }

    &:hover{
      opacity: 1;
    }
  }

  .socials{
    

    ul{
      list-style: none;
      margin: 0;
      padding: 30px 0;
      display: block;
      text-align: center;

      @include md{
        padding: 15px 0;
      }

      li{
        display: inline-block;
        padding: 5px;
        line-height: 20px;
        vertical-align: middle;

        a{
          //color: getColor( 'text-color' );
          color: #fff;
          font-size: 24px;

          &:hover{
            color: getColor( 'primary' );
          }
        }
      }
    }
  }

  .copyright{
    background: #2b2d42;
    text-align: center;
    color: #fff;
    font-size: .8rem;
    margin-top: 50px;


    p{
      padding: 30px;
      margin: 0;
    }
  }
}


.whatsapp-fixed{
  display: none;
  position: fixed;
  right: 15px;
  bottom: 65px;
  z-index: 9000;
  color: #25D366;
  font-size: 60px;
  line-height: 1;

  @include md{
    display: block;
  }
  
  &::before{
    content: '';
    display: block;
    @include border-radius(10px);
    background: #fff;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform( translate(-50%, -50%));
    
  }

  i{
    position: relative;
    z-index: 2;
  }

  @include md{
    right: 20px;
    bottom: 20px;
  }
}