@import "global";

a:link{ text-decoration: none; }

a{
    color: getTextColor( 'links:dark' );
    @include transition( all .2s ease-in-out );
    &:hover{
        color: getTextColor( 'links:hover' );
    }
}

*{
	@include box-sizing;
	-webkit-overflow-scrolling: touch; /* deixa a rolagem suave */
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0)!important; 
	-webkit-appearance: none!important;
	outline: none!important;
}



html{
	-webkit-font-smoothing: antialiased;
    -moz-smoothing: antialiased;
}

img{
  display: block;
  margin: 0;
}

html, body {
  position: relative;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
}



body{
  margin: 0 auto;
	padding: 0;
	//background: #8d99ae;
  background: #fff;
  font-family: $_font_second;
	color: #333;
  font-size: 1rem;
  min-height: 100%;
}





.site{
  margin: auto;
  background: #fff;
  min-height: 100%;
  position: relative;
  border-top: 1px solid #fff; // prevent internal margin elements
  padding-top: 50px;

  @include md{
    padding-top: 165px;
  }
}


.mobile-menu-footer{
  position: fixed;
  background: getColor( 'bg-base' );
  @include flexbox;
  @include flex-direction; //row
  @include flex-wrap; //nowrap
  @include justify-content(space-around);//flex-start
  @include align-items(center);//flex-start
  @include align-content; //stretch
  padding: .5rem 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9998;

  .btn-search{
    font-size: 27px;
    color: rgba( getColor('text-color'), .4 );
  }
  
  .btn-whats{
    font-size: 32px;
    color: #25D366;
  }

  .btn-menu{
    span{
      background: rgba( getColor('text-color'), .4 );
    }
  }

  @include md{
    display: none;
  }
}

header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
  @include transition( all .2s ease-in-out );
  background:rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);

  .header-bar{
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap; //nowrap
    @include justify-content(space-between);//flex-start
    @include align-items(stretch);//flex-start
    @include align-content; //stretch


    .left,
    .right{
      @include flexbox;
      @include flex-direction; //row
      @include flex-wrap; //nowrap
      @include justify-content;//flex-start
      @include align-items(stretch);//flex-start
      @include align-content; //stretch
    }
    .left{
      
      order: 0;
      @include align-items(center);
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;

      .btn-news{
        font-size: 30px;
        color: rgba( getColor('text-color'), .4 );
        margin-left: 20px;

        @include md{
          display: none;
        }
      }

      .header-menu-btn{
        display:none;

        @include md{
          display: block;
        }
      }


      .logo{
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;

        @include md{
          -webkit-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto;
        }
      }
    }

    .right{
      background: getColor( 'bg-base' );
      order: 0;
      

      .btn{
        color: rgba( getColor('text-color'), .4 );
        background: getColor( 'bg-base' );
        display: inline-block;
        @include flexbox;
        @include flex-direction; //row
        @include flex-wrap; //nowrap
        @include justify-content;//flex-start
        @include align-items(center);//flex-start
        @include align-content; //stretch
        @include transition( all .2s ease-in-out );
        background-size: 100% 200%;
        background-position: 0% 100%;
        padding: 10px;

        @include md{
          padding: 15px 30px;
        }
        
        &:hover{
          background-position: 0% 75%;
        }


        i{
          font-size: 26px;
          @include md{
            font-size: 60px;
            margin-right: 10px;
          }
        }
      }

      .btn-news,
      .btn-search{
        display: none;

        @include md{
          display: inline-block;
        }
      }
      
      .btn-ouvir{
        color: #fff;
        @include gradient( orange, getColor('primary') );
        display: inline-block;
        @include flexbox;
        @include flex-direction; //row
        @include flex-wrap; //nowrap
        @include justify-content;//flex-start
        @include align-items(center);//flex-start
        @include align-content; //stretch
        @include transition( all .2s ease-in-out );
        background-size: 100% 200%;
        background-position: 0% 100%;
        padding: 10px;

        @include md{
          padding: 10px 25px;
        }
        
        &:hover{
          background-position: 0% 75%;
        }


        i{
          font-size: 30px;
          @include md{
            font-size: 60px;
            margin-right: 20px;
          }
        }

        .infos{
          overflow: hidden;
          width: 0;
          height: 0;
          opacity: 0;
          @include md{
            display: block;
            width: inherit;
            height: inherit;
            opacity: 1;
          }

          h4{
            font-size: 1.2rem;
            margin: 0;
            padding: 0;
            line-height: 1;
            margin-bottom: 10px;
            @include transition( all .2s ease-in-out );
          }
  
          div{
            color: #000;
            font-size: .7rem;
            text-transform: uppercase;
            font-weight: 500;
            padding: 1px 0;
            span{
              display: inline-block;
              background: rgba(#000, .15);
              color: #fff;
              @include border-radius;// 5px
              width: 40px;
              text-align: center;
            }
          }
        }
      }// btn-ouvir

      
      
      
      
      
      
      
      @include md{
        order: 1;
      }







    }// right

    .header-menu-btn{
      // background: blue;
      @include flexbox;
      @include flex-direction; //row
      @include flex-wrap; //nowrap
      @include justify-content;//flex-start
      @include align-items(center);//flex-start
      @include align-content; //stretch
      padding: 0 20px;
    }

    .logo{
      margin: 0;
      padding: 10px;
      @include flexbox;
      @include flex-direction; //row
      @include flex-wrap; //nowrap
      @include justify-content(center);//flex-start
      @include align-items(center);//flex-start
      @include align-content; //stretch

      a{
        display: block;
        background:  url( '../img/logo-black-pink.svg' ) no-repeat center center;
        background-size: contain;
        width: 110px;
        height: 46px;
        overflow: hidden;
        text-indent: -9999px;
        

        @include md{
          width: 150px;
          height: 68px;
          @include transform(translateX(0));
        }
      }
    }
    
  }

  
}// header



.btn-menu{
  display: inline-block;
  width: 30px;
  height: 26px;
  position: relative;
  // background: red;
  span{
    display: block;
    background: getColor('text-color');
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    @include border-radius;// 5px
    @include transition( all .1s ease-in-out );

    &:nth-child(1){
      top: 3px;
    }

    &:nth-child(2){
      top: calc( 50% - 1px );
    }

    &:nth-child(3){
      bottom: 3px;
    }


  }

  &:hover{
    span{
      &:nth-child(1){
        top: 0px;
      }

      &:nth-child(3){
        bottom: 0px;
      }  
    }
  }
}


#menu-institucional{
  position: fixed;
  top:0%;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: getColor( 'bg-base' );
  z-index: 9999;
  @include flexbox;
  @include flex-direction; //row
  @include flex-wrap; //nowrap
  @include justify-content;//flex-start
  @include align-items(stretch);//flex-start
  @include align-content; //stretch

  @include transition( all .5s cubic-bezier(0,.69,.27,1.01) );

  .left{
    display: none;

    @include md{
      width: 50%;
      background: url('../img/bgmenu.jpg' ) no-repeat bottom center;
      background-size: cover;
      display:block;
    }
  }
  .right{
    width: 100%;
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap; //nowrap
    @include justify-content(center);//flex-start
    @include align-items(center);//flex-start
    @include align-content; //stretch
    
    @include md{
      width: 50%;
      
    }
  }

  &.open{
    left: 0%;
  }
  .btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .menu{
    list-style-type: none;
    margin: 0;
    padding: 15px;
    li{
      display: block;
      text-align: center;
      
      a{
        display: block;
        color: getColor( 'text-color' );
        font-size: 1.5rem;
        padding: 5px 10px 7px;
        position: relative;

        &:after{
          content: '';
          display: block;
          height: 2px;
          width: 0%;
          @include gradient( getColor( 'primary' ), #ff6a4e, left );
          position: absolute;
          bottom: 0;
          left: 50%;
          @include transform( translateX( -50% ) );
          @include transition( all .2s  cubic-bezier(0,.9,.27,1.01) );
        }

        &:hover{
          &:after{
            width: 100%;
          }
        }
      }
      &.current-menu-item{
        a{
          &:after{
            width: 100%;
          }
        }
      }
    }
  }

}




#menu-categories-overlay{
  position: fixed;
  top:0%;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: getColor( 'bg-base' );
  z-index: 9999;

  @include transition( all .5s cubic-bezier(0,.69,.27,1.01) );
  &.open{
    left: 0%;
  }
  .btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .menu-wrapper{
    @include flexbox;
    @include flex-direction(column); //row
    @include flex-wrap; //nowrap
    @include justify-content;//flex-start
    @include align-items;//flex-start
    @include align-content; //stretch
    height: 100%;

    h3{
      display: block;
      width: 100%;
      background: red;
      font-weight: normal;
      margin: 0;
      padding: 0;
      height: 70px;
      padding: 15px;
      @include flexbox;
      @include flex-direction; //row
      @include flex-wrap; //nowrap
      @include justify-content;//flex-start
      @include align-items(center);//flex-start
      @include align-content; //stretch
      background-color: darken(getColor( 'bg-base' ), 5%);
      position: relative;

      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        @include gradient( getColor( 'primary' ), #ff6a4e, left );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  
    .menu{
      width: 100%;
      margin: 0;
      padding: 10px;
      @include flex(1,1,auto);
      list-style: none;

      > li{
        display: block;
        border-bottom: 1px solid darken(getColor( 'bg-base' ), 5%);
        a{
          display: block;
          padding:10px;
          color: black;
          font-size: 1.1rem;
        }

        ul{
          margin: 0;
          padding: 0;
          list-style: none;
          padding-left: 10px;
          li{
            a{
              display: block;
              padding: 5px 10px;
              &:before{
                content: '- ';
              }
            }
          }
        }
      }
  
    }
  }

  

  
  // .menu{
  //   list-style-type: none;
  //   margin: 0;
  //   padding: 15px;
  //   li{
  //     display: block;
  //     text-align: center;
      
  //     a{
  //       display: block;
  //       color: getColor( 'text-color' );
  //       font-size: 1.5rem;
  //       padding: 5px 10px 7px;
  //       position: relative;

  //       &:after{
  //         content: '';
  //         display: block;
  //         height: 2px;
  //         width: 0%;
  //         @include gradient( getColor( 'primary' ), #ff6a4e, left );
  //         position: absolute;
  //         bottom: 0;
  //         left: 50%;
  //         @include transform( translateX( -50% ) );
  //         @include transition( all .2s  cubic-bezier(0,.9,.27,1.01) );
  //       }

  //       &:hover{
  //         &:after{
  //           width: 100%;
  //         }
  //       }
  //     }
  //     &.current-menu-item{
  //       a{
  //         &:after{
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }

}//#menu-categories-overlay



.search-overlay{
  position: fixed;
  top:0%;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: getColor( 'bg-base' );
  z-index: 9999;
  @include flexbox;
  @include flex-direction; //row
  @include flex-wrap; //nowrap
  @include justify-content(center);//flex-start
  @include align-items(center);//flex-start
  @include align-content; //stretch

  @include transition( all .5s cubic-bezier(0,.69,.27,1.01) );

  &.open{
    left: 0%;
  }

  .search-overlay-content{
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap; //nowrap
    @include justify-content(center);//flex-start
    @include align-items(center);//flex-start
    @include align-content; //stretch
    padding: 20px;
    //background: red;

    input{
      background: transparent;
      border: none;
      border-bottom: 3px solid getColor( 'text-color' );
      line-height: 40px;
      font-size: 1.5rem;
      padding: 20px;
      width: 100%;
    }

    button{
      background: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
      padding: 20px;
      color: getColor( 'text-color' );
      &:hover{
        color: getColor( 'primary' );
      }
    }
  }

  .btn-close{
    position: absolute;
    top: 20px;
    right: 20px;
  }
}//search-overlay

.btn-close{
  //background: red;
  display: block;
  width: 24px;
  height: 24px;
  position: relative;

  &:before, &:after{
    display: block;
    content: '';
    background: getColor('text-color');
    width: 100%;
    height: 3px;
    @include border-radius;// 5px
    position: absolute;
    top: 50%;
    left: 50%;
    @include transition( all .2s cubic-bezier(0,.69,.27,1.01) );
  }
  &:before{
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after{
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover{
    &:before{
      transform: translate(-50%, -50%) rotate(225deg);
    }
    &:after{
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }
}



h1.page-title{
  font-family: $_font_second;
  font-size: 2rem;
  padding: 30px 0 15px;
  margin: 0 0 30px;
  font-weight: 200;
  letter-spacing: -2px;
  line-height: 1;
  position: relative;
  border-bottom: 1px solid getColor( 'bg-base' );

  @include md{
    font-size: 3rem;
  }

  small{
    opacity: .7;
    font-size: 1.5rem;

    @include md{
      font-size: 2.5rem;
    }
    a{
      color: getColor('text-color');
      &:hover{
        color: getColor('primary');
      }
    }
  }

  

  &:after{
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 50px;
    height: 4px;
    background: getColor( 'primary' );
    @include border-radius(2px);
    z-index: 1;
  }
}






.pagination{
  h2{
      display: none;
  }

  .nav-links{
      text-align: center;
      padding: 30px 0;
      color: getColor( 'text-color' );

      span, a{
          display: inline-block;
          padding: 5px 15px;
      }

      span{
          font-weight: bold;
      }
      a{
          background: getColor( 'bg-base' );
          color: getColor( 'text-color' );
          @include transition( all .2s ease-in-out );
          @include border-radius( 5px );// ALL | T B | TL TR BR BL

          &:hover{
              background: getColor( 'primary' );
              color: #fff;
          }
      }
  }
}



.fancybox__container{
  z-index: 1000000;
}

// AddThis -->

.share-box{
    padding: 20px 0 0;
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap; //nowrap
    @include justify-content(center);//flex-start
    @include align-items(center);//flex-start
    @include align-content; //stretch
  
    .qrcode-button{
      width: 42px;
      height: 42px;
      background: rgb(102, 102, 102);
      margin-bottom: 5px;
      margin-right: 2px;
      color: #fff;
      font-size: 20px;
    
      @include flexbox;
      @include flex-direction; //row
      @include flex-wrap; //nowrap
      @include justify-content(center);//flex-start
      @include align-items(center);//flex-start
      @include align-content; //stretch
      @include transition(all ease-in-out 0.2s);
      @include transform( translateY( 0px ) );
    
    
      &:hover{
        @include transform( translateY( -3px ) );  
      }
    }

    a{
      &:hover{
        background: getColor('primary')!important;
      }
    }
}



// AddThis <--

.wp-block-quote {
  p{
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
    padding: 0 0 15px;

    &:before{
      display: inline-block;
      content: '\201C';
      font-size: 5rem;
      font-style: normal;
      font-weight: 300;
      line-height: 0;
      margin: 0;
      padding: 0;
      font-family: 'Times New Roman', Times, serif;
      @include transform( translateY( 30px ) );
      margin-right: 10px;
    }
    &:after{
      display: inline-block;
      content: '\201D';
      font-size: 5rem;
      font-style: normal;
      font-weight: 300;
      line-height: 0;
      margin: 0;
      padding: 0;
      margin-left: 10px;
      font-family: 'Times New Roman', Times, serif;
      background: red;
      @include transform( translateY( 40px ) );
    }
  }
  cite{
    font-style: italic;
  }
}






@import '_pages/pages';

@import '_pages/home';

@import '_pages/noticias';

@import '_pages/categorias';

@import '_pages/programacao';

@import '_pages/footer';






#accept-cookies{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  color: #fff;
  display: none;

  .wrapper{
    background: rgba(#000, .8);
    backdrop-filter: blur(10px);
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 50px;

    @media screen and( min-width: 575px ) {
      flex-direction: row;
    }

    .message{
      font-size: .9rem;
      font-weight: normal;
      text-shadow: #000 2px 2px 2px;
    }
    .links{
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .btn{
        border: 1px solid transparent;
        display: inline-block;
        padding: 0.7rem 1.5rem;
        background: getColor( 'primary' );
        color: #fff;
        @include border-radius( 5px );
        font-size: 1rem;
        text-align: center;
        line-height: 1;
        white-space: nowrap;

        &:hover{
          background: lighten(getColor( 'primary' ), 10%);
          color: #fff;
        }
      }
    }
  }
}