@import 'global';


.block_najua_{

  &destaques_03,
  &destaques_04,
  &destaques_05,
  &destaques_01_02_text{
      .featured{

          article{

            display: block;
            position: relative;
            overflow: hidden;
            @include border-radius( 8px );// 5px

            figure{
              margin: 0;
              display: block;
              width: 100%;
              height: 100%;
              &:after{
                content: '';
                display: block;
                background: rgba( black, .3 );
                background: linear-gradient(0deg, rgba(0,0,0,0.9) 5%, rgba(0,0,0,0.2) 50%);
                position: absolute;
                z-index: 2;
                width: 100%;
                top: 0;
                left: 0;
                height: 100%;
              }
              
              img{
                width: 105%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform( translate( -50%, -50% ) scale( 1 ) );
                @include transition( ease-in-out .3s all );
              }

            
            }

            h3{
              margin: 0;
              position: absolute;
              z-index: 3;
              bottom: 0;
              left: 0;
              display: block;
              width: 100%;
              color: #fff;
              font-size: 1.3rem;
              font-family: $_font_second;
              line-height: 1.1;
              font-weight: normal;
              // letter-spacing: -1px;
              @include transition( ease-in-out .3s all );
              padding: 10px;
              @include md{
                font-size: 1.5rem;
                padding: 15px 15px 30px;
              }
            }




            a{
              &:hover{
                img{
                  @include transform( translate( -50%, -50% ) scale( 1.1 ) );
                }

                h3{
                  bottom: -50%;
                }
              }
            }
            

        }
      }
  }


  &destaques_03{
    .featured{
      padding-top: 30px;
      padding-bottom: 30px;

      display: grid;
      
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 2fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      @include md{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 0px;
      }

      article{
        

        &:nth-child(1){
          grid-area: 1 / 1 / 2 / 3;
          @include md{
            grid-area: 1 / 1 / 2 / 3;
          }
        }
        &:nth-child(2){
          grid-area: 2 / 1 / 3 / 2;
          @include md{
            grid-area: 1 / 3 / 2 / 4;
          }
        }
        &:nth-child(3){
          grid-area: 2 / 2 / 3 / 3;
          @include md{
            grid-area: 1 / 4 / 2 / 5;
          }
        }
        
        figure{
          padding-top: 60%;          
        }

        &:nth-child(2),
        &:nth-child(3){
          figure{
            padding-top: 120%;
            @include md{
              padding-top: 60%;
            }
          }

          h3{
            font-size: 1.1rem;
            @include md{
              font-size: 1.3rem;
            }
          }
        }

      }
    }
  }

  &destaques_04{
    .featured{
      padding-top: 30px;
      padding-bottom: 30px;

      display: grid;
      grid-template-columns: repeat(2, 2fr);
      grid-template-rows: 2fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;

      @include lg{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 0px;
      }

      article{
        display: block;
        position: relative;
        overflow: hidden;
        @include border-radius( 8px );// 5px

        &:nth-child(1){
          grid-area: 1 / 1 / 2 / 2;
          @include lg{
            grid-area: 1 / 1 / 2 / 2; 
          }          
        }
        &:nth-child(2){
          grid-area: 1 / 2 / 2 / 3;
          @include lg{
            grid-area: 1 / 2 / 2 / 3;
          }
        }
        &:nth-child(3){
          grid-area: 2 / 1 / 3 / 2;
          @include lg{
            grid-area: 1 / 3 / 2 / 4;
          }
        }
        &:nth-child(4){
          grid-area: 2 / 2 / 3 / 3;
          @include lg{
            grid-area: 1 / 4 / 2 / 5;
          }
        }
        
        figure{
          padding-top: 120%;
        }

        h3{
          font-size: 1.1rem;
          @include md{
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  &destaques_05{
    .featured{
      padding-top: 30px;
      padding-bottom: 30px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px; 

      @include md{
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px; 
      }

      article{
        display: block;
        position: relative;
        overflow: hidden;
        @include border-radius(8px);// 5px
        

        &:nth-child(1){
          grid-area: 1 / 1 / 2 / 3;
          @include md{
            grid-area: 1 / 1 / 3 / 3;
          }
        }
        &:nth-child(2){
          grid-area: 2 / 1 / 3 / 2; 
          @include md{
            grid-area: 1 / 3 / 2 / 4;
          }
        }
        &:nth-child(3){
          grid-area: 2 / 2 / 3 / 3;
          @include md{
            grid-area: 1 / 4 / 2 / 5;
          }
        }
        &:nth-child(4){
          grid-area: 3 / 1 / 4 / 2;
          @include md{
            grid-area: 2 / 3 / 3 / 4;
          }
        }
        &:nth-child(5){
          grid-area: 3 / 2 / 4 / 3;
          @include md{
            grid-area: 2 / 4 / 3 / 5;
          }
        }

        
        figure{
          padding-top: 60%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5){
          h3{
            font-size: 1.1rem;

            @include md{
              font-size: 1.3rem;
            }
          }

          figure{
            padding-top: 120%;
            @include md{
                padding-top: 60%;
            }
          }

          
        }

      }      
    }
  }//destaques_05

  &destaques_01_02_text{
    .featured{
      padding-top: 30px;
      padding-bottom: 30px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px; 

      @include md{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr); 
        grid-column-gap: 30px;
        grid-row-gap: 30px; 
      }

      article{
        display: block;
        position: relative;
        overflow: hidden;
        @include border-radius(8px);// 5px
        

        &:nth-child(1){
          grid-area: 1 / 1 / 2 / 3;
          @include md{
            grid-area: 1 / 1 / 3 / 3;
          }
        }
        &:nth-child(2){
          grid-area: 2 / 1 / 3 / 2; 
          @include md{
            grid-area: 1 / 3 / 2 / 4;
          }
        }
        &:nth-child(3){
          grid-area: 2 / 2 / 3 / 3;
          @include md{
            grid-area: 2 / 3 / 3 / 4;
          }
        }

        
        figure{
          padding-top: 60%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5){
          h3{
            font-size: 1.1rem;

            @include md{
              font-size: 1.3rem;
            }
          }

          figure{
            padding-top: 120%;
            @include md{
                padding-top: 60%;
            }
          }

          
        }

      }      
    }
  }//destaque-01-02-text


  &publicidades_hor{
    
    .pub{
      padding: 30px 0;
      position: relative;

      &:after{
        font-family: $_font_second;
        content: 'PUBLICIDADE';
        position: absolute;
        top: 10px;
        left: 50%;
        @include transform( translateX( -50% ) );
        font-size: 0.5rem;
        letter-spacing: 1px;
      }
      
      
      a{
        margin: 0;
        padding: 0;
        @include flexbox;
        @include flex-direction; //row
        @include flex-wrap; //nowrap
        @include justify-content(center);//flex-start
        @include align-items(center);//flex-start
        @include align-content; //stretch

        img{
          max-width: 100%;
          display: block;
          margin: 0;
          padding: 0;
        }
      }
    }
    .pub-bg{
      background: #f1f1f1;
    }
  }// publicidades-hor

  &publicidades{
    background: #f1f1f1;
    padding: 30px 0;
    position: relative;

    &:after{
      font-family: $_font_second;
      content: 'PUBLICIDADE';
      position: absolute;
      top: 10px;
      left: 50%;
      @include transform( translateX( -50% ) );
      font-size: 0.5rem;
      letter-spacing: 1px;
    }

    .pub-{
      &mobile{
        @include md{
          display:none;
        }
      }
      &desktop{
        
        display:none;

        @include md{
          display:block;
        }
      }
    }

    .com-publicidade{
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      .pub-wrapper{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        img{
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }      
    }

  }//publicidades


}

div.header{
  @include flexbox;
  @include flex-direction;
  @include flex-wrap;
  @include align-content;
  @include justify-content(space-between);
  @include align-items(center);

  .btn-more{
    display: inline-block;
    padding: 10px 20px;
    color: getColor('text-color');
    font-size: 1rem;
    font-weight: bold;

    &:hover{
      color: getColor('primary');
    }
  }
}
.block-title{
  font-family: $_font_second;
  font-weight: 200;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -2px;
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 20px;

  
  
  &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -23px;
      background: red;
      width: 2px;
      height: 100%;

  }
}


.block-news-text-images{

  article{
    padding:15px 0;

    @include md{
      padding: 0;
    }

    a{
      figure{
        margin: 0;
        padding: 0;
        display: block;
        overflow: hidden;
        @include border-radius( 8px );// 5px
  
        img{
          width: 100%;
          height: auto;
          @include transform( scale( 1.0 ) );
          @include transition( all ease-in-out .3s );
        }
      }

      h3{
        font-size: 1.2rem;
        font-family: $_font_second;
        //font-weight: normal;
        color: getColor( 'text-color');
        line-height: 1.2;
        @include transition( all ease-in-out .2s );
      }

      &:hover{
        figure{
          img{
            @include transform( scale( 1.1 ) );
          }
        }
        h3{
          color: getColor('primary');
        }
      }
    }
    
  }


}



.block_najua_audio_player{

  .audio-player{
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    @include border-radius( 10px );
    box-shadow: -2px 2px 5px rgba(0,0,0,0.2);
    border:1px solid rgba(#000, .05);
    overflow: hidden;
    position: relative;

    h3{
      margin: 0;
      padding: 0;
      vertical-align: text-top;

      .new{
        background: getColor('primary');
        color: #fff;
        display: inline-block;
        padding: 15px 25px 4px;
        @include border-radius;
        font-size: .6rem;
        line-height: 1;
        @include transform(rotate(-30deg));
        top: -5px;
        left: -25px;
        position: absolute;
      }
    }

    .player-btn-play{
      background: none;
      cursor: pointer;
      border: none;
      padding: 10px;
      margin-right: 15px;
      font-size: 20px;
      &:hover{
        color: getColor('primary');
      }
    }
    .controls{
      @include flexbox;
      @include flex-direction; //row
      @include flex-wrap; //nowrap
      @include justify-content(space-between);//flex-start
      @include align-items(center);//flex-start
      @include align-content; //stretch

      .volume{
        max-width: 300px;
        @include flexbox;
        @include flex-direction; //row
        @include flex-wrap; //nowrap
        @include justify-content;//flex-start
        @include align-items(center);//flex-start
        @include align-content; //stretch

        i{
          margin-right: 5px;
        }
      }
    }

  }
  #player-range-volume {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;  
    background: getColor( 'bg-base' );
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: getColor('primary');
      cursor: pointer;
      border:none;
    }
    &::-moz-range-thumb{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: getColor('primary');
      cursor: pointer;
      border:none;
    }
  }


  
}



.block_najua_clubinho{
  .preloader{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    div{
      display: inline-block;
      i{
        margin-right: 10px;
      }
    }
  }
  .com-clubinho{
    .club-wrapper{
      button.slick-arrow{
        &:before{
          color: getColor('text-color');
          @include transition(all ease-in-out 0.2s);
        }

        &:hover:before{
          color: getColor('primary');
        }
      }
      .slider{
        margin: 0;
        padding: 20px 0;
       

        .item{
          padding: 10px 10px;
          .image{
            position: relative;
            
            .percent{
              position: absolute;
              top: -10px;
              right: 15px;
              padding: 10px 20px;
              background: #553a99;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 1rem;
              font-weight: bold;
              line-height: 1;

              &:after{
                content: '';
                display: block;
                border-left: 6px #22173d solid;
                border-top: 10px solid transparent;
                position: absolute;
                top: 0;
                right: -6px;
              }
            }
            img{
              width: 100%;
              height: auto;
              display: block;
              @include border-radius;
            }
          }// image
          .partner{
            text-transform: uppercase;
            color: getColor('primary');
            font-size: .8rem;
            line-height: 1;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 7px 0 7px 0;
          }
          h3{
            color: getColor('text-color');
            line-height: 1.1;
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
            font-size: 1.1rem;
          }
          p{
            color: getColor('text-color');
            font-size: .9rem;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }//com-clubinho

  .clubinho-samples{
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap; //nowrap
    @include justify-content(space-between);//flex-start
    @include align-items(center);//flex-start
    @include align-content; //stretch

    div{
      width: calc( (100% / 6) - 20px);
      height: 150px;
      background: rgba( black, .3 );
      &:first-child,
      &:last-child{
        width: calc( (100% / 12) - 20px);
      }
    }
  }
}




.block_najua_feed_uol{
  .com-feed-uol{
    padding: $padding;

    .block-title{
      display: flex;
      justify-content: space-between;
    }

    .feed-list{
      max-height: 400px;
      overflow-x: auto;

      @include sm{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-height: inherit;
        gap: 0 1rem;
      }

      @include lg{
        grid-template-columns: repeat(3, 1fr);
      }
      
      article{
        display: flex;
        border-bottom: 1px solid #ddd;
        align-items: center;
        padding: ($padding * 2) 0;

        img{
          max-width: 142px;

        }

        h3{
          margin: 0;
          padding: 0;

          a{
            color: getColor( 'text-color');
            font-size: 1.1rem;
            font-weight: bold;
  
            &:hover{
              color: getColor( 'primary' );
            }
          }
        }

        

        img{
          border-radius: .5rem;
          margin-right: 1rem;
        }
      }
    }
  }
}





ul.news-list-w-titles{
  list-style: none;
  margin: 0;
  padding: 0;

  li{
    display: block;
    border-bottom: 1px solid getColor('border-color');

    &:last-child{
      border-bottom: none;
    }

    a{
      display: block;
      padding: 25px 0;
      color: getColor( 'text-color');
      font-size: 1.1rem;
      font-weight: bold;

      &:hover{
        color: getColor( 'primary' );
      }
    }
  }
}
