.programacao{





  .programacao-content{
    
    th{
      border-bottom: 2px solid getColor( 'bg-base' );
      padding: 3px 5px;
    }

    tbody{
      tr{
        td{
          padding: 3px 5px;
          border-bottom: 1px solid getColor( 'bg-base' );

          &:first-child{
            text-align: center;
            font-size: .8rem;
          }
        }
      }
    }
  }


  .programacao-content{
    .programacao-day{
      padding-left: 100px;

      .programacao-day-title{
        font-size: 1.1rem;
        font-weight: bold;
        margin: 0;
        padding: 0;
        background: getColor( 'primary' );
        color: #fff;
        padding: 5px 15px;
        border-radius: 4px;
        display: inline-block;
        margin-left: -100px;
      }

      .prog-card{
        padding: 5px 10px;
        // border-bottom: 1px solid black;
        position: relative;
        margin-top: 10px;
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -10px;
          //background: getColor( 'bg-base' );
          width: 10px;
          border-top: 2px solid getColor( 'bg-base' );
          border-bottom: 2px solid getColor( 'bg-base' );
          border-left: 2px solid getColor( 'bg-base' );
          height: 100%;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:after{
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 1px);
          left: -18px;
          background: getColor( 'bg-base' );
          width: 8px;
          height: 2px;
        }
  
  
        h3{
          margin: 0;
          font-size: 1rem;
          font-weight: 600;
          font-family: $_font_second;
          margin-bottom: 3px;
        }
  
        .infos{
          // @include flexbox;
          // @include flex-direction; //row
          // @include flex-wrap; //nowrap
          // @include justify-content;//flex-start
          // @include align-items;//flex-start
          // @include align-content; //stretch
          font-size: .9rem;
          
  
          label{
            display: block;
            font-weight: bold;
            text-transform: uppercase;
            font-size: .6rem;
          }
  
          .horario{
            // padding-right: 10px
            position: absolute;
            top: 50%;
            left: -100px;
            width: 90px;
            font-size: .8rem;
            @include transform( translateY(-50%) );
            font-family: 'Courier New', Courier, monospace;
            text-align: center;
          }
          .apresentacao{
            // padding-left: 10px;
          }
        }
      }
    }
  }


}