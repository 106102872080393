// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1281px;

$screen-sizes : (
    "sm" : 576px,
    "md" : 768px,
    "lg" : 992px,
    "xl" : 1281px
);


// Small devices
@mixin sm {
   @media screen and (min-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media screen and (min-width: #{$screen-md-min}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media screen and (min-width: #{$screen-lg-min}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media screen and  (min-width: #{$screen-xl-min}) {
       @content;
   }
}