.categorias-page{
  // margin-top: 165px;
  .cat-list{
    display: none;

    @include md{
      display: block;
    }
  }

  .categories-list{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      display: block;
      a{
        padding: 10px;
        display: block;
        border-bottom: 1px solid getColor( 'bg-base' );
        color: getColor( 'text-color' );
        
        &:hover{
          color: getColor( 'primary' );
        }

        &[aria-current="page"]{
          background: getColor('bg-base');
          position: relative;
        }
      }

      ul{
        margin: 0;
        padding: 0;
        li{
          
          a{
            &:before{
              content: '- ';
            }
          }
        }
      }
    }
  }

  .list-news{
    article{
      display: block;
      padding: 15px 0;
      border-bottom: 1px solid getColor( 'bg-base' );

      a{
        @include flexbox;
        @include flex-direction; //row
        @include flex-wrap; //nowrap
        @include justify-content;//flex-start
        @include align-items;//flex-start
        @include align-content; //stretch
        color: getColor( 'text-color' );

        .image{
          @include order;//0
          @include flex(0, 1, 100px);//0,1,auto
          @include align-self;//auto

          @include md{
            @include flex(0, 1, 150px);//0,1,auto
          }

          img{
            width: 100%;
            height: auto;
            @include transition( all .5s ease-in-out );
            @include border-radius(10px);// 5px
          }
        }
        .image + .infos{
          padding-left: 15px;
          @include md{
            padding-left: 30px;
          }
        }
        .infos{
          @include order;//1
          @include flex(1, 1, calc(100% - 100px));//1,1,auto
          @include align-self;//auto
          @include md{
            @include flex(1, 1, calc(100% - 150px));//1,1,auto
          }

          h4{
            @include transition( all .3s ease-in-out );
            font-size: 1.3rem;
            font-weight: normal;
            margin: 0;
            padding: 0;
            letter-spacing: -1px;

            @include md{
              font-size: 1.6rem;
            }
          }
          .time{
            opacity: .7;
            font-size: .8rem;
          }

          .description{
            font-family: $_font_first;
            line-height: 1.5;
          }
        }

        &:hover{
          
          .infos{
            h4{
              color: getColor( 'primary' );
            }
            .description{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}