$_font_first  : 'Open Sans', sans-serif;
$_font_second : $_font_first;

$padding: 15px!default;
$margin: 15px!default;

$width_max_content: 1400px!default;
$grid_columns: 12;
$grid_gutter: $padding * 2;







$menu_bar : (
    'mobile' : (
        'height'      : 50px,
        'logo:width'  : 90px,
        'logo:height' : 32px,
    ),
    'desktop' : (
        'height'              : 80px,
        'height:resized'      : 50px,
        'logo:width'          : 149px,
        'logo:height'         : 50px,
        'logo:width:resized'  : 100px,
        'logo:height:resized' : 40px,
    ),
);



// @function getMenuBar( $names... ){
//     $menu : $menu_bar;
    
//     @each $definition in $names{
//         $menu: map-get( $menu, $definition );
//     }
//     @return $menu;
// }

