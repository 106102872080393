// --------------------------------------------------
// Flexbox SASS mixins
// --------------------------------------------------

// Flexbox display
@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// Inline-box display
@mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($fg: 0, $fs: 1, $fb: auto) {
    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;

    // Box-Flex only supports a flex-grow value so lets grab the
    // first item in the list and just return that.
    @if type-of($fg) == 'list' {
        $fg-boxflex: nth($fg, 1);
    }

    -webkit-box: $fg-boxflex;
    -moz-box: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
// default: row
@mixin flex-direction($value: row) {
    @if $value == row-reverse {
      -webkit-box-direction: reverse;
      -webkit-box-orient: horizontal;
      -moz-box-direction: reverse;
      -moz-box-orient: horizontal;
    } @else if $value == column {
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -moz-box-direction: normal;
      -moz-box-orient: vertical;
    } @else if $value == column-reverse {
      -webkit-box-direction: reverse;
      -webkit-box-orient: vertical;
      -moz-box-direction: reverse;
      -moz-box-orient: vertical;
    } @else {
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-direction: normal;
      -moz-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap( $wrap : nowrap ) {
    -webkit-flex-wrap: $wrap;
        -moz-flex-wrap: $wrap;
        -ms-flex-wrap: $wrap;
            flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow( $flow ) {
    -webkit-flex-flow: $flow;
        -moz-flex-flow: $flow;
        -ms-flex-flow: $flow;
            flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order( $val : 0 ) {
    -webkit-box-ordinal-group: $val;  
       -moz-box-ordinal-group: $val;     
               -ms-flex-order: $val;     
                -webkit-order: $val;  
                        order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow( $grow ) {
    -webkit-flex-grow: $grow;
       -moz-flex-grow: $grow;
        -ms-flex-grow: $grow;
            flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number> 
@mixin flex-shrink( $shrink ) {
    -webkit-flex-shrink: $shrink;
       -moz-flex-shrink: $shrink;
        -ms-flex-shrink: $shrink;
            flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width> 
@mixin flex-basis( $width ) {
    -webkit-flex-basis: $width;
       -moz-flex-basis: $width;
        -ms-flex-basis: $width;
            flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
      -webkit-box-pack: start;
      -moz-box-pack: start;
      -ms-flex-pack: start;
    } @else if $value == flex-end {
      -webkit-box-pack: end;
      -moz-box-pack: end;
      -ms-flex-pack: end;
    } @else if $value == space-between {
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
    } @else if $value == space-around {
      -ms-flex-pack: distribute;
    } @else {
      -webkit-box-pack: $value;
      -moz-box-pack: $value;
      -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
  }

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    @if $value == flex-start {
      -ms-flex-line-pack: start;
    } @else if $value == flex-end {
      -ms-flex-line-pack: end;
    } @else {
      -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($value: flex-start) {
    @if $value == flex-start {
      -webkit-box-align: start;
      -moz-box-align: start;
      -ms-flex-align: start;
    } @else if $value == flex-end {
      -webkit-box-align: end;
      -moz-box-align: end;
      -ms-flex-align: end;
    } @else {
      -webkit-box-align: $value;
      -moz-box-align: $value;
      -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    @if $value == flex-start {
      -ms-flex-item-align: start;
    } @else if $value == flex-end {
      -ms-flex-item-align: end;
    } @else {
      -ms-flex-item-align: $value;
    }
    align-self: $value;
}

%flexbox-base{
  @include flexbox;
  @include flex-direction; //row
  @include flex-wrap; // nowrap
  @include justify-content; // flex-start
  @include align-content; //stretch
  @include align-items; //flex-start
}

%flex-base{
  @include order; //0
  @include flex; //0,1,auto
  @include align-self; //auto
}