@mixin browser_prefix($proper, $value){
    $browsers_prefix: ('webkit', 'moz', 'ms', 'o');

    @each $browser in $browsers_prefix {
        #{'-' + $browser + '-' + $proper}: $value;
    }
    #{$proper}: $value;
}


@mixin    box-sizing($value:border-box){ @include browser_prefix(box-sizing, $value); }
@mixin    box-shadow($value)           { @include browser_prefix(box-shadow, $value);     }
@mixin     transform($value)           { @include browser_prefix(transform, $value);  }
@mixin border-radius($value: 5px)           { @include browser_prefix(border-radius, $value);}
@mixin   user-select($value:none)      { @include browser_prefix(user-select, $value);}

@mixin opacity($opacity) {
     opacity: $opacity;
    filter: alpha(opacity=($opacity * 100));
}

@mixin font-size($size) {
    font-size: $size;
    font-size: ($size / 16px) * 1rem;
 }

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }

    @include browser_prefix(animation, $animations);
}



@mixin transition($transition...){
    $max: length($transition);
    $transitions: '';

    @for $i from 1 through $max {
        $transitions: #{$transitions + nth($transition, $i)};

        @if $i < $max {
            $transitions: #{transitions + ", "};
        }
    }


    @include browser_prefix(transition, $transition);
}

@mixin gradient($color_1, $color_2, $direction:top, $pos_ini:0%, $pos_end:100%){
    $w3c_direction: to bottom;

    @if($direction == top){
        $w3c_direction: to bottom;
    }
    @if($direction == left){
        $w3c_direction: to right;
    }
    @if($direction == -45deg){
        $w3c_direction: 135deg;
    }
    @if($direction == 45deg){
        $w3c_direction: $direction;
    }

    background: $color_1; /* Old browsers */
    background: -moz-linear-gradient($direction,  $color_1 $pos_ini, $color_2 $pos_end); /* FF3.6-15 */
    background: -webkit-linear-gradient($direction,  $color_1 $pos_ini,$color_2 $pos_end); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient($w3c_direction,  $color_1 $pos_ini,$color_2 $pos_end); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color_1}', endColorstr='#{$color_2}',GradientType=1 ); /* IE6-9 */

}



@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @-ms-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin placeholder() {

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

@function encodecolor($string) {
    @if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
    @return $string;
}


@mixin column-width ( $value: 150px ) {
    -webkit-column-width: $value;
    -moz-column-width: $value;
    column-width: $value;
}




/*
 * Implementation of themes
 */
@mixin themify( $themes, $same: false ) {
  @each $theme, $map in $themes {

    @if $same == true {
        &.theme-#{ $theme }{
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get( map-get( $themes, $theme ), '#{$key}' );
                $theme-map: map-merge( $theme-map, ( $key: $value ) ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }@else{
        .theme-#{ $theme } & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get( map-get( $themes, $theme ), '#{$key}' );
                $theme-map: map-merge( $theme-map, ( $key: $value ) ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
  }
}

@function themed( $key ) {
  @return map-get( $theme-map, $key );
}

@function sqrt($r) {
    $x0: 1;
    $x1: $x0;

    @for $i from 1 through 10 {
        $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
        $x0: $x1;
    }

    @return $x1;
}

@function hypotenuse( $a, $b ){
    @return sqrt( ( ($a * $a ) + ( $b * $b ) ) );
}





@function pow($number, $exponent) {
    $value: 1;
  
    @if $exponent > 0 {
      @for $i from 1 through $exponent {
        $value: $value * $number;
      }
    }
  
    @return $value;
  }
  
  @function luminance($color) {
      $colors: (
        'red': red($color),
        'green': green($color),
        'blue': blue($color)
      );
    
      @each $name, $value in $colors {
        $adjusted: 0;
        $value: $value / 255;
    
        @if $value < 0.03928 {
          $value: $value / 12.92;
        } @else {
          $value: ($value + .055) / 1.055;
          $value: pow($value, 2.4);
        }
    
        $colors: map-merge($colors, ($name: $value));
      }
    
      @return (map-get($colors, 'red') * .2126) + (map-get($colors, 'green') * .7152) + (map-get($colors, 'blue') * .0722);
    }
  





    @mixin custom-radios( $radio_color: rgba( #000, .2), $radio_color_selected: red, $radio_color_shadow: rgba(#fff, 1), $size: 1px){
        position: relative;
      
        input[type=radio]{
            position: absolute;
            left: -3px;
            top: -2px;
            opacity: 0;
    
            + label{
                margin-right: 10px;
              
                &:before {
                      content: '';
                      background: transparent;
                      border-radius: 100%;
                      border: $size solid $radio_color;
                      display: inline-block;
                      width: 16px;
                      height: 16px;
                      position: relative;
                      margin-right: 7px;
                      vertical-align: top;
                      cursor: pointer;
                      text-align: center;
                      transition: all 250ms ease-in-out;
                }
              
                &:empty {
                    &:before {
                      margin-right: 0;
                    }
                }
            }
          
            &:checked + label{
                &:before{
                    background: $radio_color_selected;
                    box-shadow: inset 0 0 0 4px $radio_color_shadow;
                }
            }
          
          
            &:focus {
                + label {
                  &:before {
                    outline: none;
                    border: $size solid rgba($radio_color_selected ,0.4);
                  }
                }
            }
            
        }
    }
    
    
    
    @mixin custom-checkbox( $radio_color: rgba( #000, .2), $radio_color_selected: red, $radio_color_shadow: rgba(#fff, 1), $size: 1px, $radius: 4px){
        position: relative;
      
        input[type=checkbox]{
            position: absolute;
            left: -3px;
            top: -2px;
            opacity: 0;
    
            + label{
                margin-right: 10px;
              
                &:before {
                      content: '';
                      background: transparent;
                      border-radius: $radius;
                      border: $size solid $radio_color;
                      display: inline-block;
                      width: 16px;
                      height: 16px;
                      position: relative;
                      margin-right: 7px;
                      vertical-align: top;
                      cursor: pointer;
                      text-align: center;
                      transition: all 250ms ease-in-out;
                }
              
                &:empty {
                    &:before {
                      margin-right: 0;
                    }
                }
            }
          
            &:checked + label{
                &:before{
                    background: $radio_color_selected;
                    box-shadow: inset 0 0 0 4px $radio_color_shadow;
                }
            }
          
          
            &:focus {
                + label {
                  &:before {
                    outline: none;
                    border: $size solid rgba($radio_color_selected ,0.4);
                  }
                }
            }
            
        }
    }





    @mixin custom-checkbox-wpcf7( $radio_color: rgba( #000, .2), $radio_color_selected: red, $radio_color_shadow: rgba(#fff, 1), $size: 1px, $radius: 4px){
        position: relative;
        
        .wpcf7-list-item{
            margin:0;
        }
      
        input[type=checkbox]{
            position: absolute;
            left: -3px;
            top: -2px;
            opacity: 0;
            width: 100%;
            height: 16px;
            cursor: pointer;
            z-index: 5;
            margin: 0!important;
            padding: 0!important;
    
            + .wpcf7-list-item-label{
                margin-right: 10px;
              
              
                &:before {
                      content: '';
                      //background: transparent;
                      border-radius: $radius;
                      border: $size solid $radio_color;
                      display: inline-block;
                      width: 16px;
                      height: 16px;
                      position: relative;
                      margin-right: 7px;
                      vertical-align: top;
                      //cursor: pointer;
                      text-align: center;
                      transition: all 250ms ease-in-out;
                      z-index: 1;
                }
              
                &:empty {
                    &:before {
                      margin-right: 0;
                    }
                }
            }
          
            &:checked + .wpcf7-list-item-label{
                &:before{
                    background: $radio_color_selected;
                    box-shadow: inset 0 0 0 4px $radio_color_shadow;
                }
            }
          
          
            &:focus {
                + .wpcf7-list-item-label {
                  &:before {
                    outline: none;
                    border: $size solid rgba($radio_color_selected ,0.4);
                  }
                }
            }
            
        }
    }
    