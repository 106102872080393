.container{
    
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: $width_max_content + ($padding * 2 ); // soma o tamanho maximo + os paddings(dobro) laterais
    padding: 0 $padding;

    @include md{
        max-width: $width_max_content + ($padding * 4 ); // soma o tamanho maximo + os paddings(dobro) laterais
        padding: 0 ( $padding * 2 );
    }

    &--fluid{
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
        padding: 0 $padding;
    }

    &-md{
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding: 0;

        @include md{
            max-width: $width_max_content + ($padding * 4 ); // soma o tamanho maximo + os paddings(dobro) laterais
            padding: 0 ( $padding * 2 );
        }
    }
    
}

.row{
    //min-width: 100%;
    margin-left: ( $padding * -1 );
    margin-right: ( $padding * -1 );
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap(wrap); // nowrap
    @include justify-content; // flex-start
    @include align-content; //stretch
    @include align-items( stretch );
}

.align-items{
    &-center{
        @include align-items( center );
    }
}

%col-order {
    @include order; // 0
}

%col{
    width: 100%;
    padding-right: $padding;
    padding-left: $padding;
    position: relative;
}
.col{
    max-width: 100%;
    @include flex-grow( 1 );
    @extend %col;

    &-auto{
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }


    @for $i from 1 through $grid_columns{
        &-#{$i}{
            @extend %col;
            @include order; //0
            @include flex-basis( 100% / $grid_columns * $i );
            max-width: ( 100% / $grid_columns * $i ) ;
            width: ( 100% / $grid_columns * $i );
        }
    }
    @each $size_name, $size in $screen-sizes{
        @media screen and (min-width: #{$size}) {
            
        @for $i from 0 through $grid_columns{
                
                &-#{$size_name}-#{$i}{
                    @include flex-basis( 100% / $grid_columns * $i );
                    max-width: ( 100% / $grid_columns * $i ) ;
                    width: ( 100% / $grid_columns * $i ) ;
                }
                
            }

        }
        
    }


    
    @each $size_name, $size in $screen-sizes{
        
            
        @for $i from 0 through $grid_columns{
                
            &-#{$size_name}-#{$i}{
                @extend %col;
                @extend %col-order;
            }
            
        }
        
    }
}


/**
Columns Offset
**/

.offset{

    @for $i from 1 through $grid_columns{
        &-#{$i}{
            margin-left: ( 100% / $grid_columns * $i );
        }
    }
    @each $size_name, $size in $screen-sizes{
        @media screen and (min-width: #{$size}) {
            
        @for $i from 0 through $grid_columns{
                
                &-#{$size_name}-#{$i}{
                    margin-left: ( 100% / $grid_columns * $i );
                }
                
            }

        }
        
    }
}



%half{
    @include order; //0
    @include flex( 0, 1, auto ); //0,1,auto
    @include align-self; //auto
    width: 100%;
    max-width: 100%;

    &:nth-child(odd){
        @include flexbox;
        @include flex-direction; //row
        @include flex-wrap; // nowrap
        @include justify-content( flex-end );
        @include align-content; //stretch
        @include align-items; //flex-start
    }

    .content{
        width: 100%;
        max-width: $width_max_content / 2;
        padding: $padding;
    }
}

.columns{
    @include flexbox;
    @include flex-direction; //row
    @include flex-wrap( wrap );
    @include justify-content; // flex-start
    @include align-content; //stretch
    @include align-items( stretch );


   

    
    @each $size_name, $size in $screen-sizes{
        @for $i from 0 through $grid_columns{
            &-#{$size_name}-half{
                .column{
                    @extend %half;
                }
            }
        }
    }


    @each $size_name, $size in $screen-sizes{
        @media screen and (min-width: #{$size}) {
                
                &-#{$size_name}-half{
                    .column{
                        @include flex-basis( 50% );
                        max-width: 50%;
                    }
                }

        }
    }

    // &--half{
    //     .column{
    //         @include order; //0
    //         @include flex( 0, 1, 50% ); //0,1,auto
    //         @include align-self; //auto
    //     }
    // }
    
}